.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 243, 209, 0.767); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #333333; /* Darker background for the modal */
  color: #f0f0f0; /* Light text color */
  padding: 2rem;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  position: relative;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease, zoomOut 0.3s ease;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #f0f0f0;
}

.details {
  margin-top: 1rem;
}

details summary {
  cursor: pointer;
  font-weight: bold;
}

.modal-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
