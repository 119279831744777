/* Hero Section with Enhanced Background and Styling */
.hero-section {
  background: url('path-to-hero-image.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  padding: 120px 20px;
  text-align: center;
  border-bottom: 8px solid #FF9933; /* Saffron color accent */
}

/* Applying Indian Flag Colors More Boldly */
body {
  background-color: #FFFFFF; /* Keeping the body background white for clean design */
}

/* Typography Color Adjustments */
h1, h2, h3, h4, h5, h6, .text-primary {
  color: #0d9488; /* Cool teal color */
}

.text-secondary {
  color: #64748b; /* Muted blue-gray */
}

/* Button Styling for Vibrant Visuals */
button {
  background-color: #0d9488; /* Teal color for primary action buttons */
  color: white;
  border-radius: 20px; /* Rounded buttons for a modern look */
  padding: 10px 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

button:hover {
  background-color: #0a7d72; /* Slightly darker teal on hover */
}

/* Featured Dishes and Cards Enhancements */
.featured-dishes {
  background-color: #FFFFFF; /* White background for cleanliness */
  padding: 40px 0; /* More padding for airy feel */
  box-shadow: inset 0 -8px 8px -8px #FF9933; /* Saffron accent shadow */
}

.dish-card {
  border: none; /* Removing borders for a cleaner card look */
}

/* About Us and FAQ Section Styling */
.about-us, .faq-section {
  background-color: #f8fafc; /* Light gray for subtle distinction */
  padding: 40px 20px; /* Ample padding for content breathing space */
  margin: 20px 0; /* Margin for separation between sections */
  border-radius: 10px; /* Rounded corners for modern card appearance */
}

/* Custom Styles for Links and Icons in FAQ and About Us */
a, .MuiSvgIcon-root {
  color: #0d9488; /* Teal color for icons and links */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-section {
    padding: 80px 10px;
  }

  button {
    padding: 8px 20px; /* Smaller buttons on mobile */
  }

  .featured-dishes, .about-us, .faq-section {
    padding: 20px 10px; /* Less padding for tighter mobile layout */
  }
}
