.navBar {
  background-color: #0d9488 !important; /* Cool teal color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navTitle {
  flex-grow: 1;
  text-decoration: none;
  color: white !important;
  font-weight: bold;
}

.navLinks button {
  margin-right: 10px;
  color: white !important;
}

.navLinks .signUpButton {
  background-color: #ffcc00 !important;
  color: black !important;
  border-radius: 20px;
}

.navLinks .signUpButton:hover {
  background-color: #e6b800 !important;
}

.MuiDrawer-paper {
  width: 250px;
}

.MuiListItem-root {
  text-align: center;
}
