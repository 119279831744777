/* AboutUs.css */
.about-section {
  background-color: #ccffcc; /* Light green background */
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.about-text {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  color: #4CAF50; /* Green color for icons */
}

@media (max-width: 768px) {
  .contact-info {
      flex-direction: column;
      align-items: center;
  }
}
