.faq-section {
    animation: fadeIn 0.8s ease-out both;
    padding: 40px;
    background-color: #e6e6e6; /* Slightly darker background for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
  }
  
  h2 {
    color: #0047AB;
    margin-bottom: 20px;
    font-size: 2.5rem;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
